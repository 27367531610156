<template>
  <button @click="goToPreviousPage"
          class="font-oswald text-[5vw] leading-[6vw] md:text-[1.25vw] md:leading-[1.5vw] flex space-x-[2vw] md:space-x-[0.5vw] items-center antialiased p-[1vw] md:p-[0.25vw] pr-[4vw] md:pr-[1vw] group"
  >
    <span class="flex items-center overflow-hidden justify-center w-[8vw] h-[8vw] md:w-[2vw] md:h-[2vw] rounded-full bg-indigo relative">
      <span class="absolute inset-0 duration-150 group-hover:bg-white/20" />
      <IconArrowLeftLine class="w-[5vw] h-[5vw] md:w-[1.25vw] md:h-[1.25vw] text-white" />
    </span>
    <span class="tracking-[-0.01em] uppercase font-medium"><slot /></span>
  </button>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';
import { useNuxtApp } from '#imports';
import { navigateTo } from '#app';

const { $event } = useNuxtApp();
const router = useRouter();

function goToPreviousPage() {
  $event('back-link');
  // if previous route exists in router state go to it
  // if previous document referrer exists and is on the same domain, go to it
  // otherwise go to landing page
  if (router.options.history.state.back) {
    return router.go(-1);
  } else if (document.referrer && document.referrer.includes(window.location.origin)) {
    return navigateTo(document.referrer, { external: true });
  } else {
    return navigateTo('/', { external: true });
  }
}
</script>


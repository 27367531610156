<template>
  <div class="fixed bottom-0 md:translate-y-0 left-0 right-0 bg-white text-indigo md:py-[1.125vw] md:rounded-t-[1.25vw] rounded-t-[5vw] py-[5vw] duration-300 ease-out"
       :class="[isOpen ? 'translate-y-0' : 'translate-y-[calc(100%-16vw)]']"
  >
    <Container class="relative flex flex-wrap justify-between">
      <span class="font-medium uppercase font-oswald tracking-[-0.01em] text-[6vw] leading-[7vw] md:text-[1.875vw] md:leading-[2.25vw]">Play Game</span>

      <ButtonTiny v-if="!isOpen"
                  @click="isOpen = true"
                  class="md:hidden"
      >
        Download
        <IconArrowDownSFill class="h-[5vw] w-[5vw] md:h-[1.5vw] md:w-[1.5vw] fill-white" />
      </ButtonTiny>

      <ButtonTiny v-else
                  @click="isOpen = false"
                  class="md:hidden"
      >
        Close
        <IconClose class="h-[5vw] w-[5vw] md:h-[1.5vw] md:w-[1.5vw] fill-white" />
      </ButtonTiny>

      <div class="md:flex md:absolute inset-0 items-center justify-center w-full md:h-[2.5vw] grid grid-cols-2 gap-[2vw] md:gap-[0.5vw] pt-[5vw] md:pt-0">
        <ButtonStore v-if="stores.arcade"
                     :type="ButtonStoreType.ARCADE"
                     :href="stores.arcade"
                     class="md:h-[2.5vw] md:w-[8.125vw]"
        />

        <ButtonStore v-if="stores.apple"
                     :type="ButtonStoreType.APPLE"
                     :href="stores.apple"
                     class="md:h-[2.5vw] md:w-[8.125vw]"
        />

        <ButtonStore v-if="stores.google"
                     :type="ButtonStoreType.GOOGLE"
                     :href="stores.google"
                     class="md:h-[2.5vw] md:w-[8.125vw]"
        />

        <ButtonStore v-if="stores.huawei"
                     :type="ButtonStoreType.HUAWEI"
                     :href="stores.huawei"
                     class="md:h-[2.5vw] md:w-[8.125vw]"
        />

        <ButtonStore v-if="stores.amazon"
                     :type="ButtonStoreType.AMAZON"
                     :href="stores.amazon"
                     class="md:h-[2.5vw] md:w-[8.125vw]"
        />
      </div>
    </Container>
  </div>
</template>


<script setup lang="ts">
import { onMounted, ref, onBeforeUnmount } from 'vue';
import { ButtonStoreType } from '~/enums';

type SectionGameDownloadProps = {
      stores: {
        arcade: string;
        apple: string;
        google: string;
        huawei: string;
        amazon: string;
    }
}

defineProps<SectionGameDownloadProps>();
const isOpen = ref(false);
const lastScrollPosition = ref(0);
const scrollOffset = ref(128);

const onScroll = () => {
  if (window.pageYOffset < 0) {
    return;
  }
  if (Math.abs(window.pageYOffset - lastScrollPosition.value) < scrollOffset.value) {
    return;
  }

  if (isOpen.value) {
    isOpen.value = false;
  }

  lastScrollPosition.value = window.pageYOffset;
};

onMounted(() => {
  lastScrollPosition.value = window.pageYOffset;

  // Delay running the onscroll at mount, preventing hiding the navigation on load if not on top of the page
  setTimeout(() => {
    window.addEventListener('scroll', onScroll, { passive: true });
  }, 100);
});

onBeforeUnmount(() => {
  window.removeEventListener('scroll', onScroll);
});

</script>

<template>
  <div class="relative">
    <IconArrowDownSFill v-if="arrow"
                        class="pointer-events-none absolute top-[1vw] right-[0.5vw] w-[1.5vw] h-[1.5vw] md:w-[1.5vw] md:h-[1.5vw] text-indigo"
    />
    <select :value="modelValue"
            :required="required"
            @input="(event: Event) => emit('update:modelValue', event.target.value)"
            class="rounded-[2.5vw] text-[4.25vw] leading-[6vw] md:rounded-[0.625vw] text-indigo antialiased md:text-[1.125vw] md:leading-[1.5vw] placeholder:text-opacity-50 md:p-[1vw] md:pr-[2vw] p-[4vw] w-full focus:ring-1 focus:ring-indigo focus:outline-none appearance-none border"
            :class="[{
              'border-red': error.length > 0,
              'border-transparent': error.length === 0,
            }, backgroundColor]"
    >
      <option value=""
              :disabled="!!modelValue"
      >
        Please select
      </option>
      <option v-for="option in options"
              :key="option.key"
              :value="option.key"
      >
        {{ option.label }}
      </option>
    </select>
    <div v-if="error.length > 0"
         class="absolute text-[3.25vw] md:text-[0.8125vw] md:bottom-[-1.25vw] bottom-[-5vw] leading-[5vw] md:leading-[1.25] text-red text-right w-full capitalize"
    >
      {{ error }}
    </div>
  </div>
</template>

<script setup lang="ts">
type SupportCardFormInputProps = {
  options: { key: string | number; label: string | number }[];
  required: boolean;
  modelValue?: string;
  error?: string;
  arrow?: boolean;
  backgroundColor: string;
};

const emit = defineEmits(['update:modelValue']);
withDefaults(defineProps<SupportCardFormInputProps>(), {
  error: '',
  modelValue: '',
  arrow: true,
  backgroundColor: 'bg-indigo/10'
});
</script>

<template>
  <div :class="`bg-${color} -mb-px`">
    <div class="relative flex md:block flex-col pb-[7vw] md:pb-0 ">
      <div class="text-[7.5vw] md:text-[3vw] leading-[7.5vw] md:leading-[3vw] pt-[8.5vw] md:pt-[2vw] uppercase text-white font-work font-extrabold text-center">
        {{ tagline }}
      </div>

      <div class="relative flex-1 mt-[16vw] md:mt-0 pt-[0vw] md:pt-[3.5vw] pb-[0vw] md:pb-[4vw]">
        <SectionRunningText class="font-extrabold text-white text-[25vw] md:text-[18.75vw] md:py-[3.5vw] leading-[35vw] whitespace-nowrap font-work"
                            :text-items="[`talking ${character}`, `talking ${character}`]"
                            text-color="white"
        />

        <div class="absolute left-[4vw] top-[40.75vw] md:top-[14vw] md:left-[16vw] -rotate-12">
          <component :is="`SvgShape${characters[character].shape}`"
                     :class="[characters[character].shapeClass, `text-${characters[character].shapeColor}`]"
          />

          <div class="absolute top-0 flex items-center justify-center min-w-full min-h-full text-white">
            <span class="font-extrabold text-center font-work"
                  :class="characters[character].shapeTextClass"
            >
              {{ shapeText }}
            </span>
          </div>
        </div>

        <NuxtImg format="auto"
                 :src="characters[character].image"
                 :alt="character"
                 class="w-[70vw] md:w-[22vw] absolute top-[2vw] md:top-[1.375vw] left-[30vw] md:left-[36.875vw]"
                 sizes="xs:72vw sm:72vw md:28vw lg:28vw xl:28vw xxl:28vw"
        />
      </div>

      <div class="px-[6vw] grid md:flex md:justify-center grid-cols-2 gap-x-[5vw] md:gap-x-[1vw] gap-y-[4vw] md:gap-y-0 pb-[20.75vw] md:pb-[2.5vw] mt-[80vw] md:mt-0">
        <ButtonLefty v-for="(button, key) in buttons"
                     :key="key"
                     class="md:max-w-fit"
                     icon="ArrowDown"
                     size="default"
                     button-color="indigo"
                     icon-background-color="white"
                     icon-color="indigo"
                     text-color="white"
                     @click="handleClick(button.anchor)"
        >
          {{ button.text }}
        </ButtonLefty>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useNuxtApp, useRuntimeConfig } from '#imports';

type SectionCharacterHeroProps = {
  character: 'tom' | 'angela' | 'ben' | 'hank' | 'ginger' | 'becca';
  color: string;
  tagline: string;
  shapeText: string;
};

const props = defineProps<SectionCharacterHeroProps>();

const { $lenisScroll, $event } = useNuxtApp();
const { cdnUrl } = useRuntimeConfig().public;

function handleClick(anchor: string) {
  $lenisScroll(anchor);
  $event('character-anchor-click', { props: { anchor, character: props.character } });
}

const buttons = [
  { text: 'Play & watch', anchor: '#play' },
  { text: 'My Cool Stuff', anchor: '#stuff' },
  { text: 'My Friends', anchor: '#friends' }
];

const characters = {
  tom: {
    shape: 'Hexagon',
    shapeColor: 'toxic',
    shapeClass: 'w-[36vw] md:w-[16vw]',
    shapeTextClass: 'text-[6.5vw] md:text-[3vw] leading-none max-w-[80%] tracking-[-0.01em]',
    image: `${cdnUrl}/assets/public/img/story/header/tom.png`
  },
  angela: {
    shape: 'Star',
    shapeColor: 'plum',
    shapeClass: 'w-[43vw] md:w-[20vw]',
    shapeTextClass: 'text-[4.25vw] md:text-[2vw] leading-none max-w-[50%] tracking-[-0.01em]',
    image: `${cdnUrl}/assets/public/img/story/header/angela.png`
  },
  ben: {
    shape: 'GearSharp',
    shapeColor: 'sky',
    shapeClass: 'w-[43.5vw] md:w-[16vw]',
    shapeTextClass: 'text-[6.5vw] md:text-[2.4vw] leading-none max-w-[60%] tracking-[-0.01em]',
    image: `${cdnUrl}/assets/public/img/story/header/ben.png`
  },
  hank: {
    shape: 'Gear',
    shapeColor: 'cobalt',
    shapeClass: 'w-[40vw] md:w-[16vw]',
    shapeTextClass: 'text-[6vw] md:text-[2.25vw] leading-none max-w-[70%] tracking-[-0.01em]',
    image: `${cdnUrl}/assets/public/img/story/header/hank-3.png`
  },
  ginger: {
    shape: 'Gear',
    shapeColor: 'turquoise',
    shapeClass: 'w-[40vw] md:w-[16vw]',
    shapeTextClass: 'text-[6.5vw] md:text-[2.6vw] leading-none max-w-[60%] tracking-[-0.01em]',
    image: `${cdnUrl}/assets/public/img/story/header/ginger.png`
  },
  becca: {
    shape: 'Gear',
    shapeColor: 'lemon',
    shapeClass: 'w-[40vw] md:w-[16vw]',
    shapeTextClass: 'text-indigo text-[6.5vw] md:text-[2.5vw] leading-none max-w-[60%] tracking-[-0.01em]',
    image: `${cdnUrl}/assets/public/img/story/header/becca.png`
  }
};
</script>
<template>
  <div class="flex max-w-full overflow-hidden font-extrabold uppercase font-work"
       :class="[`text-${textColor}`]"
  >
    <Motion :key="animDuration"
            :initial="{ x: 0 }"
            :animate="{ x: direction === 'rtl' ? [0, -textWidth] : [0, textWidth] }"
            style="will-change: transform"
            :transition="{
              duration: animDuration,
              repeat: Infinity,
              easing: 'linear'
            }"
    >
      <p ref="textContainer"
         class="leading-none whitespace-pre"
         :style="{
           marginLeft: direction === 'rtl' ? '0px' : -textWidth + 'px',
         }"
         v-html="longText"
      />
    </Motion>
  </div>
</template>

<script setup lang="ts">
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Motion } from '@motionone/vue/dist/motion-vue.ssr.js';
import { computed, onMounted, ref } from 'vue';

const props = defineProps({
  textItems: {
    type: Array,
    default: () => ['Follow us on social media', 'tiktok', 'facebook', 'instagram', 'youtube']
  },
  dotColor: {
    type: String,
    default: 'current'
  },
  direction: {
    type: String,
    default: 'rtl'
  },
  textColor: {
    type: String,
    default: 'yellow'
  }
});

const textContainer = ref(null);
const textWidth = ref(0);
const multiplier = ref(3);
let text = '';

props.textItems.forEach(item => (text += item + `<span class="text-${props.dotColor} leading-none"> • </span>`));

// We add • between the text
const longText = computed(() => {
  return text.length > 0 ? text.repeat(multiplier.value) : '';
});

const animDuration = computed(() => {
  // calculate animation duration based on text length
  return text.length > 0 ? text.length / 4 : 1;
});

function calculateWidth() {
  if (!textContainer.value) {
    return;
  }

  const container = textContainer.value;
  const parentLength = container.parentNode.parentNode.offsetWidth;
  // to prevent divison by 0
  if (textWidth.value === 0) return;
  // calculate how many times to duplicate text to scroll 100% and still cover screen

  const newMultiplier = Math.ceil((parentLength + textWidth.value) / textWidth.value);
  if (newMultiplier !== multiplier.value) multiplier.value = newMultiplier;
}

onMounted(() => {
  textWidth.value = textContainer.value.offsetWidth / multiplier.value;
  calculateWidth();
});
</script>

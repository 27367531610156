<template>
  <div class="w-full md:px-[5.62vw] font-work antialiased md:grid md:grid-cols-12">
    <figure class="col-span-10 col-start-2">
      <div ref="container"
           class="relative"
      >
        <div class="keen-slider pl-[6vw] md:pl-0">
          <div v-for="(item, index) in slides"

               :key="index"
          >
            <NuxtImg format="auto"
                      :src="item.src"
                      :alt="item.alt"
                      class="object-cover keen-slider__slide md:rounded-[1.25vw] rounded-[5vw]"
                      sizes="xs:52vw sm:52vw md:17vw lg:17vw xl:17vw xxl:17vw"
            />
          </div>
        </div>

        <button @click="slider.prev()"
                class="hidden md:block hover:text-red duration-100 absolute left-[-6vw] p-[2vw] md:p-[1.5vw] -translate-y-1/2 select-none top-1/2"
        >
          <span class="sr-only">Previous</span>
          <IconArrowRightS class="w-[6vw] h-[6vw] md:w-[3vw] md:h-[3vw] rotate-180 drop-shadow-lg" />
        </button>

        <button @click="slider.next()"
                class="hidden md:block hover:text-red duration-100 absolute right-[-6vw] p-[2vw] md:p-[1.5vw] -translate-y-1/2 select-none top-1/2"
        >
          <span class="sr-only">Next</span>
          <IconArrowRightS class="w-[6vw] h-[6vw] md:w-[3vw] md:h-[3vw] drop-shadow-lg" />
        </button>
      </div>
      <div class="px-[6vw] md:hidden">
        <div class="w-full bg-black/20 h-[0.5vw] mt-[6vw] rounded-full">
          <div class="bg-turquoise h-[0.5vw] rounded-full transition-all duration-300"
               :style="{width: progress + '%'}"
          />
        </div>
      </div>
      <figcaption v-if="caption"
                  class="text-indigo px-[6vw] md:text-center mt-[3vw] md:mt-[2vw] md:px-0 text-[4.25vw] leading-[6vw] md:text-[1.25vw] md:leading-[1.875vw] md:max-w-[47.5vw] md:mx-auto"
      >
        {{ caption }}
      </figcaption>
    </figure>
  </div>
</template>

<script setup lang="ts">
import { type KeenSliderInstance, useKeenSlider } from 'keen-slider/vue.es';
import { onMounted, ref } from 'vue';
import { screenSize } from '~/composables/screen-size';

type BlogSliderType = {
  slides: Array<{
    src: string;
    alt: string;
  }>;
  caption?: string;
};

withDefaults(defineProps<BlogSliderType>(), {
  caption: ''
});

const progress = ref(0);
const size = screenSize();

onMounted(async () => {
  // temporary fix for route change bug
  setTimeout(() => {
    if (slider.value) slider.value.update();
  }, 200);
});

function update(e: KeenSliderInstance) {
  progress.value = ((e.track.details.rel + 1) / e.track.details.slides.length) * 100;
}

const [container, slider] = useKeenSlider({
  loop: true,
  mode: 'snap',
  slides: {
    perView: 1.5,
    spacing: () => 6 * size.value.width / 100
  },
  breakpoints: {
    '(min-width: 768px)': {
      slides: {
        perView: 4,
        spacing: () => 2 * size.value.width / 100
      }
    }
  },
  slideChanged: e => update(e),
  created: e => update(e)
});
</script>

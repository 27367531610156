<template>
  <div class="md:flex md:justify-between md:items-center">
    <div class="flex justify-between items-center pb-[3vw] md:pb-0 md:block h-[10vw] md:h-auto">
      <SvgMainLogo class="w-[33vw] h-[6vw] md:w-[9.15vw] md:h-[1.69vw]" />
      <LanguageSelector v-if="false"
                        :filled="true"
                        class="block md:hidden"
      />
    </div>

    <div class="font-work text-[3.25vw] md:text-[1vw] leading-[5vw] md:leading-[1vw] md:flex pb-[5vw] md:pb-0">
      <div>© 2010-{{ new Date().getFullYear() }} Outfit7 Limited & others.&nbsp;</div>
      <div>All rights reserved.</div>
    </div>

    <div class="flex pb-[5vw] md:pb-0">
      <NuxtLink href="https://cert.privo.com/#/companies/outfit7"
                target="_blank"
                class="mr-[4.5vw] md:mr-[1.875vw]"
      >
        <NuxtImg format="auto"
                 src="https://cdn-ttf.o7web.com/assets/public/img/seal-gdpr.png"
                 alt="PRIVO GDPR Seal"
                 class="h-[11.25vw] md:h-[3.125vw] w-[21.75vw] md:w-[6vw]"
                 loading="lazy"
        />
      </NuxtLink>
      <NuxtLink href="https://cert.privo.com/#/companies/outfit7"
                target="_blank"
      >
        <NuxtImg format="auto"
                 src="https://cdn-ttf.o7web.com/assets/public/img/seal-coppa.png"
                 alt="PRIVO COPPA Seal"
                 class="h-[11.25vw] md:h-[3.125vw] w-[21.75vw] md:w-[6vw]"
                 loading="lazy"
        />
      </NuxtLink>
    </div>
  </div>
</template>

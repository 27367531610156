<template>
  <component :is="componentToShow"
             @click="handleClick"
             :href="character ? `/talking-${character}` : undefined"
  >
    <div class="rounded-[5vw] md:rounded-[1.25vw] overflow-hidden md:duration-300"
         :class="[
           `bg-${backgroundColor}`,
           {'md:hover:scale-[1.05]': character},
           {'md:px-[2.75vw] md:pt-[4vw] md:pb-[2.625vw] md:w-[18.75vw]': type === 'roulette' || type === 'default'},
           {'md:px-[1.25vw] md:pt-[1.525vw] md:pb-[1.25vw] md:w-[13.125vw]': type === 'menu'},
           {'px-[6.5vw] pt-[10vw] pb-[8vw] w-[55.5vw]': type === 'roulette'},
           {'px-[5vw] pt-[7vw] pb-[5vw] w-[41.5vw]': type === 'default' || type === 'menu'},
           {'pt-[8vw] pb-[4vw]': type === 'menu'},
         ]"
    >
      <div class="flex flex-col items-center justify-between w-full h-full">
        <!-- Wrapper for @tailwindcss/aspect-ratio padding trick -->
        <div class="w-full aspect-w-21 aspect-h-32">
          <NuxtImg format="auto"
                   :src="image"
                   :alt="title"
                   loading="lazy"
                   sizes="xs:35vw sm:35vw md:20vw lg:20vw xl:20vw xxl:20vw"
                   class="w-full h-full object-contain"
          />
        </div>
        <div class="text-center uppercase font-oswald tracking-[-0.01em]"
             :class="{
               'md:text-[1.875vw] md:leading-[2.25vw] md:pt-[2.25vw]': type === 'roulette' || type === 'default',
               'md:text-[1.5vw] md:leading-[1.75vw] md:pt-[0.725vw]': type === 'menu',
               'text-[6vw] leading-[7vw] pt-[8vw]': type === 'roulette',
               'text-[4.5vw] leading-[5.5vw] pt-[4.25vw]': type === 'default' || type === 'menu',
               'text-[6vw] leading-[7vw] pt-[4.5vw]': type === 'menu'
             }"
        >
          {{ title }}
        </div>
      </div>
    </div>
  </component>
</template>

<script lang="ts" setup>
import { useNuxtApp } from '#imports';
import { computed, resolveComponent } from 'vue';

/**
 * Character card
 *
 * We have an extra div element for various outside effects to be able to work in unison
 * with the zoom effect.
 *
 * There are 4 size variants in Figma used as 3 types:
 * - Roulette: Large card on desktop, regular on mobile
 * - Default: Large card on desktop, menu/mobile on mobile
 * - Menu: Menu desktop on desktop, menu/mobile on mobile
 */

type CardCharacterProps = {
    type?: 'menu' | 'default' | 'roulette'
    character?: false | string;
    image: string;
    backgroundColor: string;
    title: string;
}

const { $event } = useNuxtApp();
const props = withDefaults(defineProps<CardCharacterProps>(), {
  type: 'default',
  character: false
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NuxtLink = resolveComponent('NuxtLink');

/**
 * Component to render
 *
 * In order to render NuxtLink as a dynamic component, we need to use
 * the resolveComponent function.
 */
const componentToShow = computed(() => {
  if (props.character && props.character.length > 0) {
    return resolveComponent('NuxtLink');
  }
  return 'div';
});

function handleClick() {
  $event('card-character-click', { props: { character: props.character } });
}
</script>

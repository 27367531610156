<template>
  <label for="question"
         class="flex mb-[2vw] md:mb-[0.5vw] text-[3.75vw] leading-[5vw] md:text-[0.9375vw] font-bold md:leading-[1.25vw] text-indigo antialiased"
         :class="props.color"
  >
    <slot />
  </label>
</template>

<script setup lang="ts">
type Props = {
  color?: string
}

const props = withDefaults(defineProps<Props>(), {
  color: 'text-indigo'
});

</script>
<template>
  <div>
    <div class="header-vue bg-blue pt-[13vw] md:pt-[5vw] text-center px-[6.25vw] md:px-[3.375vw] flex flex-col justify-between items-center">
      <FamilyGuidanceHeaderBadge class="md:hidden mb-[5vw] mt-[18vw]">
        {{ tagline }}
      </FamilyGuidanceHeaderBadge>

      <div class="md:flex md:items-center md:my-[5vw]">
        <NuxtImg format="auto"
                 src="https://cdn-ttf.o7web.com/assets/public/img/family-guidance/angela-pose-03.png"
                 class="hidden md:block w-[22.75vw] -mb-[7vw] pr-[3vw]"
                 alt="angela"
                 sizes="xs:35vw sm:35vw md:25vw lg:25vw xl:25vw xxl:25vw"
        />

        <div class="flex-col items-center md:flex">
          <FamilyGuidanceHeaderBadge class="hidden md:block mb-[2vw]">
            {{ tagline }}
          </FamilyGuidanceHeaderBadge>

          <h1 class="text-[7.5vw] md:text-[4.5vw] leading-[8.5vw] md:leading-[4.5vw] font-work font-extrabold text-white md:max-w-[44vw]">
            {{ title }}
          </h1>
        </div>

        <NuxtImg format="auto"
                 src="https://cdn-ttf.o7web.com/assets/public/img/family-guidance/becca-01.png"
                 class="hidden md:block w-[21.75vw] -mb-[5vw] pl-[3vw]"
                 alt="becca"
                 sizes="xs:35vw sm:35vw md:25vw lg:25vw xl:25vw xxl:25vw"
        />
      </div>

      <NuxtImg format="auto"
               src="https://cdn-ttf.o7web.com/assets/public/img/family-guidance/angela_becca.png"
               class="md:hidden"
               alt="angela_becca"
               sizes="xs:85vw sm:85vw md:25vw lg:25vw xl:25vw xxl:25vw"
      />

      <div class="grid grid-cols-2 md:flex md:space-x-[1vw] gap-x-[6.5vw] md:gap-x-0 gap-y-[3vw] font-normal mt-[4vw] md:mt-0">
        <ButtonLefty v-for="(button, key) in buttons"
                     :key="key"
                     :text="button.text"
                     class="md:max-w-fit h-fit"
                     size="default"
                     icon="ArrowDown"
                     @click="handleClick(button.href)"
        >
          {{ button.text }}
        </ButtonLefty>
      </div>
      <p class="text-[3.25vw] md:text-[1.063vw] md:max-w-[43vw] leading-[5vw] md:leading-[1.5vw] pb-[6vw] mt-[4.5vw] md:mt-[1.5vw] md:pb-[1.5vw] font-work text-white">
        {{ description }}
      </p>
    </div>
    <div class="bg-blue pt-[12.5vw] md:pt-[8vw] -mt-px">
      <div class="relative overflow-hidden">
        <div class="flex justify-center pb-[6vw] md:pb-[2vw]">
          <NuxtImg format="auto"
                   src="https://cdn-ttf.o7web.com/assets/public/img/family-guidance/tom.png"
                   class="w-[87vw] md:w-[41.5vw] z-10"
                   alt="tom"
                   loading="lazy"
                   sizes="xs:87vw sm:87vw md:52vw lg:52vw xl:52vw xxl:52vw"
          />
        </div>
        <div class="relative text-[7.5vw] md:text-[4vw] text-white leading-[8.5vw] md:leading-[4vw] px-[6.25vw] md:px-[23vw] pb-[17.5vw] md:pb-[13.25vw] font-work font-extrabold text-center z-10">
          {{ keyMessage }}
        </div>
        <SvgShapeGearSvg ref="gear"
                         class="w-[200vw] md:w-[120vw] -left-[50vw] md:-left-[10vw] text-pink absolute top-[32vw] md:top-[15vw]"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useNuxtApp }from '#imports';
import { animate, scroll } from 'motion';
import { ref, onMounted } from 'vue';

type FamilyGuidanceHeaderProps = {
  tagline: string;
  title: string;
  description: string;
  keyMessage: string;
}

defineProps<FamilyGuidanceHeaderProps>();
const gear = ref();
const { $lenisScroll, $event } = useNuxtApp();

function handleClick(anchor: string) {
  $event('family-anchor', { props: { anchor } });
  $lenisScroll(anchor);
}

const buttons = [
  { text: 'Free to play', href: '#free' },
  { text: 'Certified', href: '#certified' },
  { text: 'Fun for all', href: '#safe' }
];

onMounted(() => {
  scroll(({ y }) => {
    if (!gear.value) return;

    animate(gear.value.$el,
      { transform: `rotate(${y.current / 30}deg)` },
      { duration:0 }
    );
  });
  // animate(gear.value.$el, { transform: 'rotate(-360deg) ' }, { duration: 120, repeat: Infinity });
});
</script>

<template>
  <div v-if="(social)"
       v-show="(complianceResultState.O7Compliance_AgeLimitPassed !== false)"
       class="relative overflow-hidden"
  >
    <SectionRunningText direction="ltr"
                        :text-items="scrollingText"
                        class="text-[12vw] md:text-[5vw] leading-[12vw] md:leading-[5vw]"
    />
    <SectionRunningText class="text-[12vw] md:text-[5vw] leading-[12vw] md:leading-[5vw]"
                        :text-items="scrollingText.reverse()"
    />
    <div class="relative z-10">
      <div class="mt-[10vw] md:mt-[6vw] md:flex md:justify-center">
        <CardSocialCharacter character="angela"
                             background="pink"
                             icon-color="plum"
                             :links="social.angela"
        />
        <div class="mt-[40vw] md:mt-0 md:ml-[7.5vw]">
          <CardSocialCharacter character="tom"
                               :delay="size.isMobile ? 0 : 0.5"
                               background="blue"
                               icon-color="green"
                               :links="social.tom"
          />
        </div>
      </div>
    </div>

    <SvgShapeGearSharp class="z-0 text-lemon w-[40vw] pointer-events-none h-[40vw] md:h-[18vw] md:w-[18vw] -right-[20vw] md:right-[-5vw] top-[110vw] md:top-[15vw] absolute" />
    <SvgShapeDiamond class="w-[26.5vw] md:w-[11.5vw] text-orange absolute top-[110vw] md:top-[20vw] left-[-11vw] md:left-[-4vw]"
                     :rotate="-40"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useAsyncData, queryContent } from '#imports';
import { screenSize } from '../../composables/screen-size';
import { complianceResultState } from '@outfit7/o7compliance-client';

const size = screenSize();
const { data } = await useAsyncData('social', () => queryContent('globals', 'social').findOne());

const scrollingText = ['hi', 'Ni hao', 'Bonjour', 'Konnichiwa', 'Ciao', 'Hello', 'An nyeong', 'Gutten tag', 'Hola', 'Marhaban'];

const social = computed(() => {
  if (data && data.value) {
    return data.value;
  }

  return false;
});

</script>

<template>
  <svg viewBox="0 0 256 145"
       fill="none"
       xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M218.87 35.58a35.64 35.64 0 0 0-64-21 35.625 35.625 0 0 0-43.768-9.354A35.616 35.616 0 0 0 98.82 15.47a35.65 35.65 0 0 0-63.32 23 35.654 35.654 0 1 0 1.13 71.3 35.649 35.649 0 0 0 46.223 33.436 35.654 35.654 0 0 0 17.777-12.436 35.636 35.636 0 0 0 43.765 9.345 35.643 35.643 0 0 0 12.285-10.235 35.65 35.65 0 0 0 63.32-23 35.652 35.652 0 0 0 35.08-36.22 35.655 35.655 0 0 0-36.22-35.08h.01Z"
          fill="currentColor"
    />
  </svg>
</template>

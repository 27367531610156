<template>
  <div v-if="!route.path.startsWith('/customs-privacy')"
       class="navigation fixed top-0 left-0 right-0 z-40 flex items-center overflow-hidden transition-transform duration-300 bg-white shadow-m"
       :class="[showNavigation ? 'translate-y-0' : '-translate-y-full']"
  >
    <NuxtLink href="/"
              class="h-[16vw] md:h-[5vw] w-[16vw] md:w-[5vw] bg-red flex justify-center items-center shrink-0"
    >
      <SvgTtfLogo class="w-[10vw] md:w-[3.125vw] text-white " />
      <span class="sr-only">Talking Tom and Friends logo</span>
    </NuxtLink>
    <div class="relative ml-[3.5vw] md:ml-0 flex justify-between items-center w-full md:flex-row-reverse md:justify-between md:mr-[2vw]">
      <div class="flex items-center md:relative">
        <Transition enter-active-class="duration-300"
                    enter-from-class="-translate-y-[16vw] md:-translate-y-[5vw]"
                    enter-to-class="translate-y-0"
                    leave-active-class="duration-300"
                    leave-from-class="translate-y-0"
                    leave-to-class="-translate-y-[16vw] md:-translate-y-[5vw]"
        >
          <div class="absolute right-[4vw] md:mr-0 top-0 bottom-0 flex items-center"
               v-if="menuCharactersShow"
          >
            <NavigationCharacters />
          </div>
        </Transition>
        <NavigationMenu />
      </div>

      <ol class="inline-flex flex-wrap justify-center items-center space-x-[5vw] md:space-x-[2.5vw] uppercase text-indigo font-oswald text-[4.5vw] md:text-[1.25vw] leading-[5.5vw] md:leading-[1.5vw] mr-[6vw] md:mr-0 duration-300 md:duration-[0ms]"
          :class="[
            {'translate-y-[12vw] pointer-events-none md:translate-y-0 md:pointer-events-auto': menuCharactersShow}
          ]"
      >
        <NavigationLink v-for="item in main.slice(1)"
                        :key="item.id"
                        :href="item.url"
                        :class="route.path === item.url ? 'text-red' : ''"
                        @click="handleActiveLinkClick(item.url)"
        >
          {{ item.title }}
        </NavigationLink>
      </ol>
      <div class="hidden md:block" />
    </div>
  </div>
  <div v-else>
    <Container>
      <div class="md:px-[6vw]">
        <div class="bg-red h-[16vw] md:h-[5vw] w-[16vw] md:w-[5vw] flex justify-center items-center">
          <SvgTtfLogo class="w-[10vw] md:w-[3.125vw] text-white" />
          <span class="sr-only">Talking Tom and Friends logo</span>
        </div>
      </div>
    </Container>
  </div>
</template>

<script lang="ts" setup>
import { useState } from '#imports';
import { useRoute } from 'vue-router';
import { onMounted, ref, onBeforeUnmount } from 'vue';
import { useNuxtApp } from '#imports';
import main from '../content/navigation/main.json';

// check if route has a prefix of 'customs-privacy'
const route = useRoute();

const { $lenisScroll } = useNuxtApp();

const menuOpen = useState('menuOpen', () => false);
const menuCharactersShow = useState('menuCharactersShow', () => false);
const menuCharactersOpen = useState('menuCharactersOpen', () => false);
const showNavigation = ref(true);
const lastScrollPosition = ref(0);
const scrollOffset = ref(60);

const onScroll = () => {
  if (window.pageYOffset < 0) {
    return;
  }
  if (Math.abs(window.pageYOffset - lastScrollPosition.value) < scrollOffset.value) {
    return;
  }

  if (menuOpen.value || menuCharactersOpen.value) {
    return;
  }

  showNavigation.value = window.pageYOffset < lastScrollPosition.value;
  lastScrollPosition.value = window.pageYOffset;
};

function handleActiveLinkClick(href: string) {
  if (route.path === href) $lenisScroll(0);
}

onMounted(() => {
  lastScrollPosition.value = window.pageYOffset;

  // Delay running the onscroll at mount, preventing hiding the navigation on load if not on top of the page
  setTimeout(() => {
    window.addEventListener('scroll', onScroll, { passive: true });
  }, 100);
});

onBeforeUnmount(() => {
  window.removeEventListener('scroll', onScroll);
});
</script>

<template>
  <div class="relative rounded-[5vw] md:rounded-[1.25vw] overflow-hidden md:flex flex-row md:justify-between shadow-m md:h-[40vw]"
       :class="`bg-${backgroundColor}`"
  >
    <SvgShapeFluff class="absolute right-0 bottom-0 translate-x-[30%] md:translate-x-[20%] translate-y-[50%] md:translate-y-[40%] w-[120%] md:w-[36%] h-auto text-pink" />

    <SvgShapeCircle class="absolute left-0 top-0 translate-x-[20%] md:translate-x-[40%] -translate-y-[50%] w-[50%] md:w-[27%] h-auto text-toxic" />

    <NuxtImg format="auto"
             :src="backgroundImageMobile ? backgroundImageMobile : backgroundImage"
             class="relative md:hidden mb-[10.5vw] md:mb-0 object-cover w-full"
             sizes="xs:88vw sm:88vw"
             placeholder
             loading="lazy"
             :class="type === 'square' ? 'h-[88vw]' : 'h-[66vw]'"
             :alt="title"
    />

    <div class="relative flex flex-col justify-center md:pl-[6vw] px-[6vw] md:py-[1vw]"
         :class="type === 'square' ? 'md:pr-[6vw]' : 'md:pr-[3.5vw]'"
    >
      <h3 class="tracking-[-0.01em] text-center md:text-left text-white font-work font-extrabold text-[10vw] leading-none"
          :class="type === 'square' ? 'md:text-[5vw]' : 'md:text-[4vw]'"
      >
        {{ title }}
      </h3>
      <div class="flex justify-center md:flex md:justify-start mt-[6vw] md:mt-[2.5vw] mb-[11vw] md:mb-0">
        <ButtonRegular :gate-link="gated"
                       @click="openGame"
                       class="self-start"
        >
          {{ ctaLabel }}
        </ButtonRegular>
      </div>
    </div>

    <NuxtImg format="auto"
             :src="backgroundImage ? backgroundImage : backgroundImageMobile"
             class="relative flex-shrink-0 hidden object-cover md:block"
             sizes="md:54vw lg:54vw xl:54vw xxl:54vw"
             loading="lazy"
             placeholder
             :class="type === 'square' ? 'w-[44.375vw]' : 'w-[53.125vw]'"
             :alt="title"
    />

    <MinigameModal v-if="isGameOpen"
                   v-bind="game"
                   @close-game="closeGame"
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useNuxtApp } from '#imports';

const { $event } = useNuxtApp();

const isGameOpen = ref(false);

type MinigameCardProps = {
  type?: 'square' | 'rectangle',
  title: string;
  ctaLabel: string;
  backgroundColor?: string;
  backgroundImage?: string;
  backgroundImageMobile?: string;
  gated?: boolean;
  game: {
    id: string;
    link: string;
    reward: {
      title: string;
      ctaLabel: string;
      link: string;
    }
  };
}

const props = withDefaults(defineProps<MinigameCardProps>(), {
  type: 'rectangle',
  backgroundColor: 'purple',
  backgroundImage: '',
  backgroundImageMobile: '',
  gated: false
});

function openGame() {
  isGameOpen.value = true;
  $event('minigame-open', { props: { title: props.game.id } });
}

function closeGame() {
  isGameOpen.value = false;
}

</script>
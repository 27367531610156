<template>
  <svg width="147"
       height="27"
       viewBox="0 0 147 27"
       fill="none"
       xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_8857_5911)">
      <path d="M13.2852 0.0136719C9.75466 0.0278918 6.37379 1.44127 3.88363 3.94402C1.39346 6.44678 -0.00281897 9.83473 0.000765089 13.3653C-0.0181142 15.115 0.31258 16.851 0.9735 18.4712C1.63442 20.0915 2.61225 21.5635 3.84961 22.8009C5.08696 24.0382 6.55893 25.016 8.1792 25.6769C9.79947 26.3379 11.5354 26.6686 13.2852 26.6497C16.7647 26.5715 20.0753 25.1344 22.5085 22.646C24.9417 20.1575 26.3041 16.8154 26.3041 13.335C26.3041 9.85467 24.9417 6.51259 22.5085 4.02412C20.0753 1.53565 16.7647 0.0985458 13.2852 0.0203945V0.0136719ZM13.2852 19.8194C11.5727 19.8159 9.93146 19.134 8.72058 17.9232C7.50969 16.7123 6.82785 15.071 6.8243 13.3585C6.8243 11.6414 7.50641 9.99469 8.72058 8.78052C9.93474 7.56635 11.5815 6.88424 13.2986 6.88424C15.0157 6.88424 16.6625 7.56635 17.8767 8.78052C19.0908 9.99469 19.7729 11.6414 19.7729 13.3585C19.7658 15.0745 19.0791 16.7178 17.8632 17.9287C16.6473 19.1396 15.0012 19.8194 13.2852 19.8194V19.8194Z"
            fill="#281E3C"
      />
      <path d="M49.697 0H45.3651C44.959 0 44.5695 0.161322 44.2823 0.448491C43.9951 0.73566 43.8338 1.12515 43.8338 1.53127V15.7224C43.8338 16.6433 43.468 17.5264 42.8168 18.1776C42.1656 18.8288 41.2825 19.1946 40.3616 19.1946C39.4407 19.1946 38.5575 18.8288 37.9064 18.1776C37.2552 17.5264 36.8894 16.6433 36.8894 15.7224V1.53127C36.8894 1.12515 36.728 0.73566 36.4409 0.448491C36.1537 0.161322 35.7642 0 35.3581 0H31.0531C30.6486 0.00521994 30.2621 0.168226 29.9761 0.454271C29.69 0.740316 29.527 1.12677 29.5218 1.53127V16.0044C29.5218 18.8829 30.6652 21.6434 32.7006 23.6788C34.736 25.7142 37.4966 26.8576 40.375 26.8576C43.2535 26.8576 46.014 25.7142 48.0494 23.6788C50.0847 21.6434 51.2282 18.8829 51.2282 16.0044V1.53127C51.2117 1.13044 51.0451 0.750482 50.7614 0.466814C50.4777 0.183147 50.0978 0.0165156 49.697 0V0Z"
            fill="#281E3C"
      />
      <path d="M71.5584 5.91743e-05H55.7756C55.3641 5.91743e-05 54.9695 0.163506 54.6785 0.454453C54.3876 0.7454 54.2241 1.14001 54.2241 1.55147V5.63485C54.2232 5.83883 54.2628 6.04097 54.3404 6.2296C54.4181 6.41822 54.5323 6.58962 54.6766 6.73386C54.8208 6.8781 54.9921 6.99234 55.1808 7.06999C55.3694 7.14764 55.5716 7.18715 55.7756 7.18626H59.9731V25.0578C59.9783 25.4694 60.1442 25.8627 60.4353 26.1538C60.7263 26.4449 61.1196 26.6107 61.5312 26.6159H65.7893C66.2003 26.6107 66.5928 26.4446 66.8828 26.1533C67.1729 25.8621 67.3372 25.4688 67.3406 25.0578V7.18626H71.5584C71.7624 7.18715 71.9645 7.14764 72.1532 7.06999C72.3418 6.99234 72.5131 6.8781 72.6574 6.73386C72.8016 6.58962 72.9158 6.41822 72.9935 6.2296C73.0711 6.04097 73.1107 5.83883 73.1098 5.63485V1.55147C73.1116 1.34724 73.0727 1.14469 72.9954 0.955659C72.918 0.766623 72.8038 0.594877 72.6594 0.450456C72.515 0.306035 72.3432 0.19184 72.1541 0.114508C71.9651 0.0371767 71.7626 -0.00173245 71.5584 5.91743e-05Z"
            fill="#281E3C"
      />
      <path d="M121.87 0H106.101C105.689 0 105.295 0.163447 105.004 0.454394C104.713 0.745341 104.55 1.13995 104.55 1.55142V5.63479C104.549 5.83877 104.588 6.04091 104.666 6.22954C104.743 6.41817 104.858 6.58956 105.002 6.7338C105.146 6.87804 105.318 6.99228 105.506 7.06993C105.695 7.14758 105.897 7.18709 106.101 7.1862H110.298V25.0577C110.304 25.4693 110.47 25.8626 110.761 26.1537C111.052 26.4448 111.445 26.6106 111.857 26.6159H116.115C116.526 26.6106 116.918 26.4445 117.208 26.1533C117.498 25.862 117.663 25.4687 117.666 25.0577V7.1862H121.87C122.074 7.18709 122.276 7.14758 122.465 7.06993C122.654 6.99228 122.825 6.87804 122.969 6.7338C123.114 6.58956 123.228 6.41817 123.305 6.22954C123.383 6.04091 123.423 5.83877 123.422 5.63479V1.55142C123.422 1.13995 123.258 0.745341 122.967 0.454394C122.676 0.163447 122.282 0 121.87 0Z"
            fill="#281E3C"
      />
      <path d="M89.7526 6.04792e-05H77.5897C77.1783 6.04792e-05 76.7837 0.163507 76.4927 0.454454C76.2018 0.745401 76.0383 1.14001 76.0383 1.55148V25.0578C76.0374 25.2621 76.0769 25.4645 76.1545 25.6535C76.232 25.8426 76.3462 26.0144 76.4903 26.1592C76.6345 26.3039 76.8058 26.4188 76.9945 26.4972C77.1831 26.5756 77.3854 26.6159 77.5897 26.6159H81.7739C82.1855 26.6107 82.5788 26.4449 82.8699 26.1538C83.161 25.8627 83.3268 25.4694 83.332 25.0578V17.3209H88.0333C88.4449 17.3156 88.8382 17.1498 89.1293 16.8587C89.4203 16.5676 89.5862 16.1743 89.5914 15.7627V12.2704C89.5862 11.8587 89.4203 11.4654 89.1293 11.1744C88.8382 10.8833 88.4449 10.7174 88.0333 10.7122H83.332V7.19971H89.7526C89.9569 7.2006 90.1593 7.16111 90.3483 7.08354C90.5373 7.00597 90.7092 6.89184 90.8539 6.74769C90.9987 6.60354 91.1136 6.4322 91.192 6.24354C91.2703 6.05487 91.3108 5.8526 91.3108 5.6483V1.57162C91.3143 1.36539 91.2765 1.16052 91.1997 0.969106C91.1228 0.777692 91.0084 0.603598 90.8632 0.457119C90.718 0.31064 90.5449 0.194733 90.3542 0.116231C90.1634 0.0377297 89.9588 -0.0017645 89.7526 6.04792e-05V6.04792e-05Z"
            fill="#281E3C"
      />
      <path d="M100.061 10.6772H95.8031C95.3921 10.6825 94.9995 10.8486 94.7095 11.1398C94.4195 11.4311 94.2552 11.8244 94.2517 12.2354V25.0363C94.2552 25.4473 94.4195 25.8405 94.7095 26.1318C94.9995 26.4231 95.3921 26.5891 95.8031 26.5944H100.061C100.473 26.5892 100.866 26.4233 101.157 26.1322C101.448 25.8412 101.614 25.4479 101.619 25.0363V12.2555C101.618 11.8409 101.453 11.4435 101.162 11.1485C100.871 10.8534 100.476 10.6843 100.061 10.6772V10.6772Z"
            fill="#281E3C"
      />
      <path d="M100.061 0H95.8031C95.3933 0.0052215 95.0017 0.170351 94.7119 0.460174C94.4221 0.749997 94.257 1.14158 94.2517 1.55142V5.63479C94.2552 6.04517 94.4198 6.43775 94.71 6.72794C95.0002 7.01813 95.3927 7.1827 95.8031 7.18621H100.061C100.472 7.18102 100.864 7.01613 101.155 6.72648C101.446 6.43684 101.612 6.04524 101.619 5.63479V1.55142C101.611 1.1415 101.443 0.750877 101.153 0.461588C100.862 0.172299 100.471 0.00685839 100.061 0V0Z"
            fill="#281E3C"
      />
      <path d="M129.421 26.6159H133.041C133.539 26.6269 134.028 26.482 134.44 26.2015C134.852 25.9209 135.166 25.5187 135.338 25.051L146.184 2.40435C146.296 2.16913 146.356 1.91254 146.359 1.65215C146.366 1.59189 146.366 1.53109 146.359 1.47083C146.32 1.07561 146.138 0.708183 145.848 0.437423C145.557 0.166664 145.178 0.0111485 144.781 0H129.334C128.911 -3.73176e-06 128.505 0.167477 128.206 0.465789C127.906 0.764102 127.737 1.16895 127.735 1.59171V5.67509C127.737 6.09785 127.906 6.50269 128.206 6.80101C128.505 7.09932 128.911 7.26682 129.334 7.26682H135.277C135.392 7.26659 135.504 7.29543 135.604 7.35066C135.704 7.40589 135.788 7.48567 135.848 7.58245C135.909 7.67924 135.944 7.78986 135.949 7.90385C135.955 8.01783 135.932 8.13142 135.882 8.23391L127.964 24.1981C127.844 24.4355 127.782 24.6978 127.782 24.9637C127.782 25.3135 127.892 25.6545 128.098 25.9375L128.178 26.0316C128.329 26.2148 128.519 26.3622 128.734 26.4633C128.949 26.5643 129.184 26.6164 129.421 26.6159V26.6159Z"
            fill="#281E3C"
      />
    </g>
    <defs>
      <clipPath id="clip0_8857_5911">
        <rect width="146.37"
              height="26.6427"
              fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

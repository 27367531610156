<template>
  <TransitionRoot appear
                  :show="isOpen"
                  as="template"
  >
    <Dialog as="div"
            class="relative z-40"
            data-lenis-prevent
    >
      <TransitionChild as="template"
                       enter="duration-300"
                       enter-from="-translate-y-full"
                       enter-to="translate-y-0"
                       leave="duration-300"
                       leave-from="translate-y-0"
                       leave-to="-translate-y-full"
                       @after-leave="emit('close-game');"
      >
        <DialogPanel class="web-game fixed inset-0 overflow-hidden bg-white">
          <div class="relative flex justify-center items-center w-full h-full">
            <iframe :src="link"
                    frameborder="0"
                    class="w-full h-full"
                    @load="isLoading = false"
            />
            <Transition enter-active-class="duration-300"
                        enter-from-class="opacity-0"
                        enter-to-class="opacity-100"
                        leave-active-class="duration-300"
                        leave-from-class="opacity-100"
                        leave-to-class="opacity-0"
            >
              <div v-if="isLoading"
                   class="absolute inset-0 flex items-center justify-center bg-white"
              >
                Loading...
              </div>
            </Transition>
            <Transition enter-active-class="duration-300"
                        enter-from-class="scale-0"
                        enter-to-class="scale-100"
                        leave-active-class="duration-300"
                        leave-from-class="scale-100"
                        leave-to-class="scale-0"
            >
              <div v-if="isGameCompleted"
                   class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 px-[5vw] py-[10vw] w-[80%] flex flex-col items-center justify-center bg-white rounded-[5vw] md:rounded-[1.25vw] gap-[10vw] md:gap-[5vw]"
              >
                <h3 class="tracking-[-0.01em] text-center text-indigo font-work font-extrabold text-[10vw] md:text-[5vw] leading-none">
                  {{ reward.title }}
                </h3>
                <ButtonDownload :href="reward.link"
                                @click="download"
                >
                  {{ reward.ctaLabel }}
                </ButtonDownload>
              </div>
            </Transition>
          </div>
          <button @click="close"
                  class="absolute top-[6vw] right-[6vw] md:top-[2.5vw] md:right-[2.5vw] h-[10vw] w-[10vw] md:h-[2vw] md:w-[2vw] flex justify-center items-center bg-white text-indigo rounded-full hover:opacity-90 duration-300"
          >
            <span class="sr-only">Close the popup</span>
            <IconClose class="h-[7.5vw] md:h-[1.5vw] w-[7.5vw] md:w-[1.5vw]" />
          </button>
        </DialogPanel>
      </TransitionChild>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue';
import { TransitionRoot, TransitionChild, Dialog, DialogPanel } from '@headlessui/vue';
import { useNuxtApp } from '#imports';

const { $event } = useNuxtApp();


type MinigameModalProps = {
  id: string;
  link: string;
  reward: {
    title: string;
    ctaLabel: string;
    link: string;
  }
}

const props = defineProps<MinigameModalProps>();

const emit = defineEmits(['close-game']);

const isOpen = ref(false);
const isLoading = ref(true);
const isGameCompleted = ref(false);
const isRewardClaimed = ref(false);

function close() {
  isOpen.value = false;
  if (isRewardClaimed.value) return;
  $event('minigame-close', { props: { title: props.id } });
}

function download() {
  $event('minigame-reward', { props: { title: props.id } });
  isRewardClaimed.value = true;
}

function handleGameMessage(event: MessageEvent) {
  if ('onGameEnd' in event.data && event.data.onGameEnd) {
    isGameCompleted.value = true;
    $event('minigame-end', { props: { title: props.id } });
  }
}

onMounted(() => {
  isOpen.value = true;

  window.addEventListener('message', handleGameMessage);
});

onUnmounted(() => {
  window.removeEventListener('message', handleGameMessage);
});
</script>
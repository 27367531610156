<template>
  <button @click="openMenu"
          class="h-[10vw] w-[10vw] md:h-[3vw] md:w-[3vw] flex justify-center items-center bg-indigo rounded-[2.5vw] md:rounded-[0.625vw] hover:opacity-[.85] transition-opacity duration-300 cursor-pointer"
  >
    <span class="sr-only">Open the menu</span>
    <IconMenu class="h-[4vw] md:h-[1.01vw] md:w-[1.12vw] w-[4.5vw] text-white" />
  </button>

  <TransitionRoot appear
                  :show="open"
                  as="template"
  >
    <Dialog as="div"
            data-lenis-prevent
            @close="open = false"
            class="relative z-40"
    >
      <TransitionChild as="template"
                       enter="transition duration-[750ms] ease-out-quint"
                       enter-from="-translate-y-[100vh] opacity-100"
                       enter-to="translate-y-0 opacity-100"
                       leave="transition duration-300 ease-in-cubic"
                       leave-from="translate-y-0 opacity-100"
                       leave-to="-translate-y-[100vh]"
      >
        <div className="fixed inset-0 overflow-y-auto">
          <DialogPanel class="w-full min-h-full bg-indigo">
            <div class="pb-[5vw] md:pb-[2.5vw] md:flex md:flex-row-reverse md:justify-between md:items-center md:px-[6.25vw] pt-[5.5vw]">
              <div class="flex justify-end mt-[8vw] mr-[8vw] mb-[20vw] md:m-0">
                <button @click="closeMenu('close')"
                        class="h-[10vw] w-[10vw] md:h-[3vw] md:w-[3vw] flex justify-center items-center bg-white text-indigo rounded-[2.5vw] md:rounded-[0.625vw] hover:opacity-90 duration-300"
                >
                  <span class="sr-only">Close the menu</span>
                  <IconClose class="h-[6vw] md:h-[1.5vw] w-[6vw] md:w-[1.5vw]" />
                </button>
              </div>

              <div class="mx-[11vw] md:mx-0 flex justify-evenly md:justify-start md:space-x-[1.125vw]">
                <ButtonRegular v-for="item in main.slice(1)"
                               :key="item.id"
                               :href="item.url"
                               background-color="red"
                               color="white"
                               @click="closeMenu(item.url)"
                >
                  {{ item.title }}
                </ButtonRegular>
              </div>
            </div>
            <div class="border-t-[1px] border-white/10 mx-[11vw] pb-[8vw] md:mx-[6.25vw]">
              <div class="mt-[8vw] md:mt-[3vw] space-y-[3vw] md:space-y-[1.5vw] flex flex-col">
                <NavigationPrimary v-for="item in primary"
                                   :key="item.id"
                                   :href="item.url"
                                   @click="closeMenu(item.url)"
                >
                  {{ item.title }}
                </NavigationPrimary>
              </div>
              <div class="items-end justify-between md:flex">
                <div v-show="complianceResultState.O7Compliance_AgeLimitPassed !== false"
                     class="mt-[8vw] md:mt-[3vw] flex flex-col space-y-[4vw] md:space-y-[1vw]"
                >
                  <NavigationSecondary v-for="item in secondary"
                                       :key="item.id"
                                       :href="item.url"
                                       @click="closeMenu(item.url)"
                  >
                    {{ item.title }}
                  </NavigationSecondary>
                </div>

                <div v-if="false"
                     class="mt-[16vw] md:mt-0"
                >
                  <LanguageSelector />
                </div>
              </div>
            </div>
          </DialogPanel>
        </div>
      </TransitionChild>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { useState, useNuxtApp } from '#imports';
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel
} from '@headlessui/vue';
import { complianceResultState } from '@outfit7/o7compliance-client';

import main from '../../content/navigation/main.json';
import primary from '../../content/navigation/primary.json';
import secondary from '../../content/navigation/secondary.json';

const { $event } = useNuxtApp();
const open = useState('menuOpen', () => false);
const menuCharactersOpen = useState('menuCharactersOpen');

const openMenu = () => {
  $event('menu-open');
  open.value = true;
};

const closeMenu = item => {
  $event('menu-close', { props : { link: item  } });
  if (menuCharactersOpen.value) {
    menuCharactersOpen.value = false;
    setTimeout(() => {
      open.value = false;
    }, 10);
  } else {
    open.value = false;
  }
};
</script>

<template>
  <div v-show="!(!(complianceResultState.O7Compliance_AgeLimitPassed !== false) && gated)"
       class="rounded-[5vw] md:rounded-[1.25vw] overflow-hidden md:flex flex-row md:justify-between shadow-m md:h-[40vw]"
       :class="`bg-${backgroundColor}`"
  >
    <NuxtImg format="auto"
             :src="backgroundImageMobile ? backgroundImageMobile : backgroundImage"
             class="md:hidden mb-[10.5vw] md:mb-0 object-cover w-full"
             sizes="xs:88vw sm:88vw"
             placeholder
             loading="lazy"
             :class="type === 'square' ? 'h-[88vw]' : 'h-[66vw]'"
             :alt="title"
    />

    <div class="flex flex-col justify-center md:pl-[6vw] px-[6vw] md:py-[1vw]"
         :class="type === 'square' ? 'md:pr-[6vw]' : 'md:pr-[3.5vw]'"
    >
      <h3 class="tracking-[-0.01em] text-center md:text-left text-white font-work font-extrabold text-[10vw] leading-none"
          :class="type === 'square' ? 'md:text-[5vw]' : 'md:text-[4vw]'"
      >
        {{ title }}
      </h3>
      <div class="flex justify-center md:flex md:justify-start mt-[6vw] md:mt-[2.5vw] mb-[11vw] md:mb-0">
        <ButtonRegular v-bind="cta"
                       :gate-link="gated"
                       @click="handleClick"
                       class="self-start"
        >
          {{ cta.label }}
        </ButtonRegular>
      </div>
    </div>

    <NuxtImg format="auto"
             :src="backgroundImage ? backgroundImage : backgroundImageMobile"
             class="flex-shrink-0 hidden object-cover md:block"
             sizes="md:68vw lg:68vw xl:68vw xxl:68vw"
             loading="lazy"
             placeholder
             :class="type === 'square' ? 'w-[44.375vw]' : 'w-[53.125vw]'"
             :alt="title"
    />
  </div>
</template>

<script setup lang="ts">
import { useNuxtApp } from '#imports';
import type { CtaType } from '../../types';
import { complianceResultState } from '@outfit7/o7compliance-client';

type CardBigProps = {
  type?: 'square' | 'rectangle',
  title: string,
  cta: CtaType;
  backgroundColor?: string;
  backgroundImage?: string;
  backgroundImageMobile?: string;
  gated?: boolean;
}

const props = withDefaults(defineProps<CardBigProps>(), {
  type: 'square',
  backgroundColor: 'sky',
  backgroundImage: '',
  backgroundImageMobile: '',
  gated: false
});

const { $event } = useNuxtApp();

function handleClick() {
  $event('card-big-click', { props : { title: props.title, href: props.cta.href } });
}
</script>
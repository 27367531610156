<template>
  <button @click="open = !open"
          class="border rounded-[2.5vw] md:rounded-[0.625vw] h-[10vw] md:h-[2.5vw] text-[4vw] md:text-[1vw] leading-none pl-[3vw] md:pl-[0.75vw] md:pr-[0.25vw] font-oswald md:hover:bg-indigo/10 transition-colors duration-300 font-medium border-indigo flex items-center"
  >
    <span class="uppercase">Characters</span>
    <IconArrowDownSFill class="transition-transform duration-300 w-[6vw] md:w-[1.5vw]"
                        :class="open ? 'rotate-180' : ''"
    />
  </button>

  <Teleport to="body">
    <Transition enter-active-class="duration-[750ms] ease-out-quint"
                enter-from-class="-translate-y-[100vh]"
                enter-to-class="translate-y-0"
                leave-active-class="duration-300 ease-in-cubic"
                leave-from-class="translate-y-0"
                leave-to-class="-translate-y-[100vh]"
    >
      <div v-show="open"
           data-lenis-prevent
           class="fixed z-20 inset-0 overflow-y-auto pt-[16vw] md:pt-[5vw] w-full h-full bg-indigo"
      >
        <div class="w-full min-h-full bg-indigo py-[7.25vw] pb-[24vw] md:py-[4vw]">
          <div class="px-[6vw] h-fit md:px-0 md:flex md:justify-center">
            <div class="grid grid-cols-2 md:grid-cols-4 md:grid-row-1 gap-[5vw] md:gap-[2vw] text-white">
              <CardCharacter @click="open = false"
                             v-for="card in cardsList"
                             :key="card.title"
                             :title="card.title"
                             :background-color="card.color"
                             :image="card.image"
                             :character="card.url"
                             type="menu"
              />
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script setup>
import { useState, useNuxtApp } from '#imports';
import { watch } from 'vue';

const { $event } = useNuxtApp();
const open = useState('menuCharactersOpen', () => false);

// watch open state
watch(open, value => {
  if (value) {
    $event('menu-characters-open');
  } else {
    $event('menu-characters-close');
  }
});

const cardsList = [
  { image: 'https://cdn-ttf.o7web.com/assets/public/img/characters/roulette/tom.png', title: 'Talking Tom', color: 'blue', url: 'tom' },
  { image: 'https://cdn-ttf.o7web.com/assets/public/img/characters/roulette/angela.png', title: 'Talking Angela', color: 'pink', url: 'angela' },
  { image: 'https://cdn-ttf.o7web.com/assets/public/img/characters/roulette/ginger.png', title: 'Talking Ginger', color: 'orange', url: 'ginger' },
  { image: 'https://cdn-ttf.o7web.com/assets/public/img/characters/roulette/ben.png', title: 'Talking Ben', color: 'green', url: 'ben' },
  { image: 'https://cdn-ttf.o7web.com/assets/public/img/characters/roulette/becca.png', title: 'Talking Becca', color: 'purple', url: 'becca' },
  { image: 'https://cdn-ttf.o7web.com/assets/public/img/characters/roulette/hank.png', title: 'Talking Hank', color: 'yellow', url: 'hank' }
];

</script>

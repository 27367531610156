<template>
  <div>
    <!-- games anchor for direct linking -->
    <SectionFilter id="games"
                   :title="content.filter.title"
                   :character="selectedCharacter"
                   :categories="categories"
                   :type="selectedType"
                   :hide="true"
    >
      <div class="mt-[8vw] md:mt-[4vw] grid md:grid-cols-3 md:gap-y-[4vw] gap-y-[10vw] md:gap-x-[2vw]">
        <CardGame v-for="item in games.slice(0, toShow)"
                  :key="item.id"
                  v-bind="item"
        />
      </div>

      <div class="flex"
           v-if="games.length > toShow"
      >
        <ButtonLefty @click="showMore"
                     background-color="white"
                     color="indigo"
                     class="mx-auto mt-[12vw] md:mt-[4vw] self-start"
                     icon="ArrowDown"
        >
          {{ content.filter.cta }}
        </ButtonLefty>
      </div>
    </SectionFilter>
  </div>
</template>

<script setup lang="ts">
import { useAsyncData, queryContent, useNuxtApp  } from '#imports';
import { useRoute, useRouter } from 'vue-router';
import { computed, ref, watch } from 'vue';

type TemplateVideosProps = {
    content: any
}
const { $event } = useNuxtApp();
const props = defineProps<TemplateVideosProps>();

const defaultToShow = 6;
const loadMore = 6;
const toShow = ref(defaultToShow);
const route = useRoute();
const router = useRouter();
const selectedCharacter = ref(route.query.character ? route.query.character as string :'all');
const selectedType = ref(route.query.type ? route.query.type as string : 'all');

const { data } = await useAsyncData('games', () => queryContent('games').sort({ order: 1, $numeric: 1 }).find());

const categories = [
  {
    value: 'all',
    label: 'All Games'
  },
  {
    value: 'runners',
    label: 'Runners'
  },
  {
    value: 'virtual-friends',
    label: 'Virtual Friends'
  }
];

watch(
  () => route.query.character,
  character => {
    selectedCharacter.value = character ? character as string : 'all' ;

    router.push({
      query: {
        ...route.query,
        type: undefined
      }
    });
  }
);

watch(
  () => route.query.type,
  type => {
    selectedType.value = type ? type as string : 'all';
  }
);

watch(
  [
    () => route.query.type,
    () => route.query.character
  ],
  () => {
    toShow.value = defaultToShow;
  }
);

function showMore(){
  $event('show-more', { props: { title: 'games' } });
  toShow.value = toShow.value + loadMore;
}

const games = computed(() => {
  if (data && data.value && data.value.length > 0) {

    let items = data.value;

    // Selecting a character also changes the game order…
    if (selectedCharacter.value && selectedCharacter.value !== 'all') {
      // If the filter by character is present, we filter the items
      items = items.filter(item => props.content.order[selectedCharacter.value].includes(item.id));

      // order the items by props.content.order[selectedCharacter.value]
      items.sort((a, b) => {
        return props.content.order[selectedCharacter.value].indexOf(a.id) - props.content.order[selectedCharacter.value].indexOf(b.id);
      });
    }

    if (selectedType.value && selectedType.value !== 'all') {
      items = items.filter(item => item.categories.includes(selectedType.value));
    }

    return items;

  }
  return [];
});
</script>

<template>
  <section class="relative overflow-hidden pt-[16vw] md:pt-[5vw]">
    <Container class="pb-[14vw] md:pb-[6vw] md:pt-[10vw] pt-[20vw]">
      <SvgShapeStar class="absolute top-[13vw] md:-top-[1vw] left-0 md:left-[15vw] w-[20vw] md:w-[13vw] text-yellow" />
      <SvgShapeCrescent class="absolute top-[12vw] md:top-[6vw] w-[20.7vw] md:w-[12vw] text-turquoise -right-[12vw] md:-right-[1vw] z-[1]"
                        :rotate="-60"
      />
      <SvgShapeDiamond class="absolute w-[16.25vw] md:w-[8.75vw] text-orange -left-[3vw] top-[93vw] md:top-auto md:bottom-[2vw]"
                       :rotate="-35"
      />
      <AnimateFadeIn>
        <h1 class="font-work mx-auto md:max-w-[56vw] text-indigo font-extrabold tracking-[-0.01em] text-[14vw] md:text-[5vw] leading-none text-center md:mb-[3vw] mb-[8vw]">
          {{ title }}
        </h1>
      </AnimateFadeIn>

      <AnimateFadeIn>
        <div class="flex justify-center w-full">
          <ButtonLefty class="mx-auto"
                       icon="ArrowDownLine"
                       @click="handleClick"
          >
            {{ cta }}
          </ButtonLefty>
        </div>
      </AnimateFadeIn>
    </Container>
  </section>
</template>

<script setup lang="ts">
import { useNuxtApp } from '#imports';

const { $lenisScroll, $event } = useNuxtApp();

type SectionHeroProps = {
  title: string;
  cta: string
}

defineProps<SectionHeroProps>();

function handleClick() {
  $event('anchor-all');
  $lenisScroll('#all');
}
</script>
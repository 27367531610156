<template>
  <Container v-show="(complianceResultState.O7Compliance_AgeLimitPassed !== false)">
    <AnimateFadeIn>
      <div class="social-vue relative rounded-[5vw] md:rounded-[1.5vw] overflow-hidden bg-purple h-[88vw] md:h-[30vw] md:flex md:items-center">
        <div class="text-[10vw] px-[4vw] md:px-0 md:text-[5vw] text-center md:text-start leading-[10vw] md:leading-[5vw] pt-[16vw] md:pt-0 md:ml-[6.5vw] md:max-w-[32vw] text-white font-work font-extrabold">
          Join me on social media
        </div>
        <div class="absolute inset-0 flex justify-between"
             ref="container"
        >
          <div v-for="(item, i) in socials"
               :key="i"
               class="absolute top-0 inline-block"
               :style="{ transform: `translate3d(${positions[i].x}px, ${positions[i].y}px, 0) ` }"
          >
            <div :style="{ transform: `translate3d(-50%, -50%, 0) rotate(${positions[i].rotation}rad)` }">
              <SectionCharacterSocialButton :icon="item.label"
                                            :href="social[item.key]"
                                            :label="item.label"
                                            @click="trackEvent('social', { props: { label: item.label } })"
              />
            </div>
          </div>
        </div>
      </div>
    </AnimateFadeIn>
  </Container>
</template>
<script setup lang="ts">
import { computed, ref, onMounted, watch  } from 'vue';
import Matter, { Body } from 'matter-js';
import { useAsyncData, queryContent, useNuxtApp } from '#imports';
import { complianceResultState } from '@outfit7/o7compliance-client';
import { screenSize } from '~/composables/screen-size';
import { inView } from 'motion';

const { $event } = useNuxtApp();
const trackEvent = $event;

const container = ref();
const running = ref(true);
const size = screenSize();


type SectionCharacterSocialProps = {
  character: 'tom' | 'angela' | 'ben' | 'hank' | 'ginger' | 'becca';
}

const props = defineProps<SectionCharacterSocialProps>();
const { data } = await useAsyncData('social', () => queryContent('globals', 'social').findOne());

const social = computed(() => {
  if (data && data.value && (props.character === 'tom' || props.character === 'angela')) {
    return data.value[props.character];
  }

  return false;
});

const socials = [
  { key: 'facebook', label: 'Facebook' },
  { key: 'instagram', label: 'Instagram' },
  { key: 'tiktok', label: 'TikTok' },
  { key: 'youtube', label: 'Youtube' }
];

// module aliases
const Engine = Matter.Engine,
  Bodies = Matter.Bodies,
  Composite = Matter.Composite,
  Composites = Matter.Composites;

const positions = ref([]);
positions.value = socials.map(word => {
  return { x: 0, y: 0, rotation: 0 };
});

onMounted(() => {
  inView(container.value, () => {
    running.value = true;
    setup();
  }, { amount: 0.25 });
});


// create an engine
let engine: Matter.Engine | undefined;
let stack: Matter.Composite;
let world: Matter.World;
let ground: Matter.Body;

const vwToPx = (vw: number) => (vw * size.value.width) / 100;

const setup = () => {
  if(container.value){
    const containerWidth = container.value.clientWidth;
    const containerHeight = container.value.clientHeight;

    engine = Engine.create();
    world = engine.world;
    stack = Composites.stack(
      !size.value.isMobile ? window.innerWidth / 1.7 : window.innerWidth/4,
      size.value.isMobile ? -window.innerHeight : -window.innerHeight*2,
      1,
      positions.value.length,
      size.value.isMobile ? vwToPx(5) : vwToPx(1.25),
      100,
      function (x: number, y: number, column: number, row: number, lastBody: Body, i: number) {
        if (i + 1 > positions.value.length) return;
        const element = container.value.children[i];
        const width = element.offsetWidth;
        const height = element.offsetHeight;

        const minX = size.value.isMobile ? vwToPx(1) : containerWidth / 2;
        const maxX = size.value.isMobile ? containerWidth - vwToPx(10) : containerWidth - vwToPx(10);
        const position = Math.random() * (maxX - minX) + minX;
        return Bodies.rectangle(position, y, width, height, { chamfer: { radius: height / 2 }, mass: width / 10, angle: Math.random() * 2 - 1 });
      }
    );

    ground = size.value.isMobile
      ? Bodies.rectangle(containerWidth, containerHeight- vwToPx(0.3), containerWidth*2, 0.01, {
        isStatic: true
      })
      : Bodies.rectangle(containerWidth, containerHeight, containerWidth, 0.01, {
        isStatic: true
      });

    const wallLeft = size.value.isMobile
      ? Bodies.rectangle(vwToPx(0.5), containerHeight, 0.01, containerHeight, { isStatic: true })
      : Bodies.rectangle(containerWidth / 2.1, containerHeight, 0.01, containerHeight, { isStatic: true });
    const wallRight = size.value.isMobile
      ? Bodies.rectangle(containerWidth - vwToPx(0.5), containerHeight/2, 0.01, containerHeight, { isStatic: true })
      : Bodies.rectangle(containerWidth - vwToPx(0.2), containerHeight, 0.01, containerHeight, { isStatic: true });

    Composite.add(world, [stack, ground, wallRight, wallLeft]);
    render();
  }
};

const clear = () => {
  if (Engine) Engine.clear(engine);
  Composite.clear(world, true);
};
const render = () => {
  if (Engine) Engine.update(engine, 20);
  positions.value = stack.bodies.map(body => {
    return { x: body.position.x, y: body.position.y, rotation: body.angle };
  });
  if (running.value) window.requestAnimationFrame(render);
  else clear();
};
</script>

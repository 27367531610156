<template>
  <svg viewBox="0 0 144 32"
       fill="none"
       xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M44.7594 4.99681C44.2336 3.02978 42.6849 1.48101 40.7178 0.955237C37.1525 -3.53769e-06 22.8572 0 22.8572 0C22.8572 0 8.56189 -3.53769e-06 4.99654 0.955237C3.02978 1.48101 1.48074 3.02978 0.954961 4.99681C0 8.56189 0 16.0003 0 16.0003C0 16.0003 0 23.4384 0.954961 27.0032C1.48074 28.9702 3.02978 30.5193 4.99654 31.045C8.56189 32 22.8572 32 22.8572 32C22.8572 32 37.1525 32 40.7178 31.045C42.6849 30.5193 44.2336 28.9702 44.7594 27.0032C45.7149 23.4384 45.7149 16.0003 45.7149 16.0003C45.7149 16.0003 45.7149 8.56189 44.7594 4.99681Z"
          fill="white"
    />
    <path d="M44.7594 4.99681C44.2336 3.02978 42.6849 1.48101 40.7178 0.955237C37.1525 -3.53769e-06 22.8572 0 22.8572 0C22.8572 0 8.56189 -3.53769e-06 4.99654 0.955237C3.02978 1.48101 1.48074 3.02978 0.954961 4.99681C0 8.56189 0 16.0003 0 16.0003C0 16.0003 0 23.4384 0.954961 27.0032C1.48074 28.9702 3.02978 30.5193 4.99654 31.045C8.56189 32 22.8572 32 22.8572 32C22.8572 32 37.1525 32 40.7178 31.045C42.6849 30.5193 44.2336 28.9702 44.7594 27.0032C45.7149 23.4384 45.7149 16.0003 45.7149 16.0003C45.7149 16.0003 45.7149 8.56189 44.7594 4.99681Z"
          fill="white"
    />
    <path d="M18.2856 22.8574L30.1623 16.0002L18.2856 9.14276V22.8574Z"
          fill="#FF0050"
    />
    <path d="M71.4222 25.9732C71.6725 25.32 71.7982 24.252 71.7982 22.7698V16.5255C71.7982 15.0873 71.6725 14.0358 71.4222 13.3709C71.1716 12.7065 70.7301 12.3739 70.0985 12.3739C69.4882 12.3739 69.0573 12.7065 68.807 13.3709C68.5565 14.0358 68.431 15.0873 68.431 16.5255V22.7698C68.431 24.252 68.5506 25.32 68.7908 25.9732C69.0302 26.6272 69.4658 26.9539 70.0985 26.9539C70.7301 26.9539 71.1716 26.6272 71.4222 25.9732ZM66.3554 29.1119C65.4503 28.5024 64.8072 27.5539 64.4264 26.2674C64.0448 24.9821 63.8545 23.271 63.8545 21.1351V18.2257C63.8545 16.068 64.072 14.3351 64.5082 13.0279C64.9438 11.7202 65.6247 10.7666 66.5514 10.1672C67.4775 9.56809 68.6925 9.26825 70.1965 9.26825C71.6786 9.26825 72.8662 9.57341 73.7599 10.1837C74.6533 10.7938 75.3073 11.7476 75.7216 13.0439C76.1351 14.3412 76.3423 16.068 76.3423 18.2257V21.1351C76.3423 23.271 76.1407 24.9877 75.7376 26.2842C75.3341 27.5813 74.6801 28.529 73.7761 29.1281C72.8713 29.7275 71.6459 30.0271 70.0985 30.0271C68.5069 30.0271 67.2591 29.7219 66.3554 29.1119Z"
          fill="white"
    />
    <path d="M71.4222 25.9732C71.6725 25.32 71.7982 24.252 71.7982 22.7698V16.5255C71.7982 15.0873 71.6725 14.0358 71.4222 13.3709C71.1716 12.7065 70.7301 12.3739 70.0985 12.3739C69.4882 12.3739 69.0573 12.7065 68.807 13.3709C68.5565 14.0358 68.431 15.0873 68.431 16.5255V22.7698C68.431 24.252 68.5506 25.32 68.7908 25.9732C69.0302 26.6272 69.4658 26.9539 70.0985 26.9539C70.7301 26.9539 71.1716 26.6272 71.4222 25.9732ZM66.3554 29.1119C65.4503 28.5024 64.8072 27.5539 64.4264 26.2674C64.0448 24.9821 63.8545 23.271 63.8545 21.1351V18.2257C63.8545 16.068 64.072 14.3351 64.5082 13.0279C64.9438 11.7202 65.6247 10.7666 66.5514 10.1672C67.4775 9.56809 68.6925 9.26825 70.1965 9.26825C71.6786 9.26825 72.8662 9.57341 73.7599 10.1837C74.6533 10.7938 75.3073 11.7476 75.7216 13.0439C76.1351 14.3412 76.3423 16.068 76.3423 18.2257V21.1351C76.3423 23.271 76.1407 24.9877 75.7376 26.2842C75.3341 27.5813 74.6801 28.529 73.7761 29.1281C72.8713 29.7275 71.6459 30.0271 70.0985 30.0271C68.5069 30.0271 67.2591 29.7219 66.3554 29.1119Z"
          fill="white"
    />
    <path d="M136.362 12.7482C136.134 13.0318 135.981 13.4948 135.905 14.1374C135.828 14.7804 135.791 15.7558 135.791 17.0636V18.5023H139.092V17.0636C139.092 15.7777 139.048 14.8023 138.962 14.1374C138.874 13.473 138.717 13.0049 138.488 12.7319C138.259 12.4595 137.904 12.3232 137.425 12.3232C136.945 12.3232 136.591 12.4651 136.362 12.7482ZM135.791 21.183V22.1964C135.791 23.4826 135.828 24.4465 135.905 25.0893C135.981 25.7324 136.139 26.2013 136.379 26.4956C136.619 26.7896 136.989 26.9371 137.491 26.9371C138.166 26.9371 138.629 26.675 138.88 26.1521C139.13 25.6291 139.266 24.7576 139.288 23.5366L143.179 23.7656C143.201 23.9406 143.211 24.1797 143.211 24.4846C143.211 26.3374 142.705 27.7215 141.691 28.6368C140.678 29.552 139.245 30.01 137.392 30.01C135.169 30.01 133.611 29.3126 132.718 27.9172C131.824 26.523 131.377 24.3653 131.377 21.4445V17.946C131.377 14.9386 131.84 12.7431 132.767 11.359C133.693 9.97485 135.278 9.28278 137.523 9.28278C139.07 9.28278 140.258 9.5661 141.087 10.1327C141.914 10.7 142.498 11.5824 142.835 12.7809C143.173 13.9797 143.342 15.6363 143.342 17.75V21.183H135.791Z"
          fill="white"
    />
    <path d="M136.362 12.7482C136.134 13.0318 135.981 13.4948 135.905 14.1374C135.828 14.7804 135.791 15.7558 135.791 17.0636V18.5023H139.092V17.0636C139.092 15.7777 139.048 14.8023 138.962 14.1374C138.874 13.473 138.717 13.0049 138.488 12.7319C138.259 12.4595 137.904 12.3232 137.425 12.3232C136.945 12.3232 136.591 12.4651 136.362 12.7482ZM135.791 21.183V22.1964C135.791 23.4826 135.828 24.4465 135.905 25.0893C135.981 25.7324 136.139 26.2013 136.379 26.4956C136.619 26.7896 136.989 26.9371 137.491 26.9371C138.166 26.9371 138.629 26.675 138.88 26.1521C139.13 25.6291 139.266 24.7576 139.288 23.5366L143.179 23.7656C143.201 23.9406 143.211 24.1797 143.211 24.4846C143.211 26.3374 142.705 27.7215 141.691 28.6368C140.678 29.552 139.245 30.01 137.392 30.01C135.169 30.01 133.611 29.3126 132.718 27.9172C131.824 26.523 131.377 24.3653 131.377 21.4445V17.946C131.377 14.9386 131.84 12.7431 132.767 11.359C133.693 9.97485 135.278 9.28278 137.523 9.28278C139.07 9.28278 140.258 9.5661 141.087 10.1327C141.914 10.7 142.498 11.5824 142.835 12.7809C143.173 13.9797 143.342 15.6363 143.342 17.75V21.183H135.791Z"
          fill="white"
    />
    <path d="M55.3695 20.8076L50.2369 2.27139H54.7155L56.5135 10.6729C56.9715 12.744 57.3091 14.5092 57.5269 15.969H57.658C57.8103 14.923 58.1482 13.169 58.6712 10.7056L60.5346 2.27139H65.0135L59.8157 20.8076V29.7001H55.3695V20.8076Z"
          fill="white"
    />
    <path d="M55.3695 20.8076L50.2369 2.27139H54.7155L56.5135 10.6729C56.9715 12.744 57.3091 14.5092 57.5269 15.969H57.658C57.8103 14.923 58.1482 13.169 58.6712 10.7056L60.5346 2.27139H65.0135L59.8157 20.8076V29.7001H55.3695V20.8076Z"
          fill="white"
    />
    <path d="M90.9089 9.66022V29.7001H87.3782L86.9857 27.2487H86.8877C85.9286 29.1013 84.4901 30.0271 82.5723 30.0271C81.2431 30.0271 80.2624 29.5915 79.6302 28.7197C78.998 27.8482 78.6822 26.4856 78.6822 24.6333V9.66022H83.1936V24.3715C83.1936 25.2655 83.2919 25.9029 83.4878 26.2837C83.6841 26.6656 84.0111 26.8562 84.4685 26.8562C84.8611 26.8562 85.2371 26.7364 85.5962 26.4965C85.956 26.2565 86.2228 25.9519 86.3975 25.5813V9.66022H90.9089Z"
          fill="white"
    />
    <path d="M90.9089 9.66022V29.7001H87.3782L86.9857 27.2487H86.8877C85.9286 29.1013 84.4901 30.0271 82.5723 30.0271C81.2431 30.0271 80.2624 29.5915 79.6302 28.7197C78.998 27.8482 78.6822 26.4856 78.6822 24.6333V9.66022H83.1936V24.3715C83.1936 25.2655 83.2919 25.9029 83.4878 26.2837C83.6841 26.6656 84.0111 26.8562 84.4685 26.8562C84.8611 26.8562 85.2371 26.7364 85.5962 26.4965C85.956 26.2565 86.2228 25.9519 86.3975 25.5813V9.66022H90.9089Z"
          fill="white"
    />
    <path d="M114.05 9.66022V29.7001H110.519L110.127 27.2487H110.029C109.069 29.1013 107.631 30.0271 105.713 30.0271C104.384 30.0271 103.403 29.5915 102.771 28.7197C102.139 27.8482 101.823 26.4856 101.823 24.6333V9.66022H106.335V24.3715C106.335 25.2655 106.433 25.9029 106.629 26.2837C106.825 26.6656 107.152 26.8562 107.61 26.8562C108.002 26.8562 108.378 26.7364 108.737 26.4965C109.097 26.2565 109.364 25.9519 109.539 25.5813V9.66022H114.05Z"
          fill="white"
    />
    <path d="M114.05 9.66022V29.7001H110.519L110.127 27.2487H110.029C109.069 29.1013 107.631 30.0271 105.713 30.0271C104.384 30.0271 103.403 29.5915 102.771 28.7197C102.139 27.8482 101.823 26.4856 101.823 24.6333V9.66022H106.335V24.3715C106.335 25.2655 106.433 25.9029 106.629 26.2837C106.825 26.6656 107.152 26.8562 107.61 26.8562C108.002 26.8562 108.378 26.7364 108.737 26.4965C109.097 26.2565 109.364 25.9519 109.539 25.5813V9.66022H114.05Z"
          fill="white"
    />
    <path d="M103.168 5.90057H98.6894V29.7001H94.276V5.90057H89.7974V2.27167H103.168V5.90057Z"
          fill="white"
    />
    <path d="M103.168 5.90057H98.6894V29.7001H94.276V5.90057H89.7974V2.27167H103.168V5.90057Z"
          fill="white"
    />
    <path d="M124.846 21.038C124.846 22.4986 124.785 23.6428 124.666 24.4706C124.546 25.2993 124.345 25.8878 124.061 26.2361C123.778 26.5849 123.396 26.7591 122.917 26.7591C122.546 26.7591 122.203 26.672 121.887 26.4976C121.571 26.3232 121.315 26.0617 121.119 25.7128V14.3362C121.271 13.7917 121.533 13.3446 121.904 12.9957C122.274 12.6477 122.677 12.4727 123.113 12.4727C123.571 12.4727 123.925 12.6528 124.175 13.012C124.426 13.3717 124.6 13.9764 124.699 14.827C124.797 15.6767 124.846 16.8861 124.846 18.4553V21.038ZM128.981 12.8639C128.708 11.6001 128.267 10.6846 127.657 10.1174C127.047 9.55103 126.207 9.2677 125.14 9.2677C124.312 9.2677 123.538 9.50231 122.819 9.97069C122.1 10.4394 121.544 11.055 121.152 11.8176H121.119L121.119 1.25818H116.771V29.6998H120.498L120.956 27.8039H121.053C121.402 28.4795 121.925 29.0134 122.622 29.4059C123.32 29.7981 124.094 29.9944 124.944 29.9944C126.469 29.9944 127.592 29.2911 128.311 27.8857C129.03 26.48 129.39 24.2845 129.39 21.2981V18.1275C129.39 15.8827 129.253 14.1282 128.981 12.8639Z"
          fill="white"
    />
    <path d="M124.846 21.038C124.846 22.4986 124.785 23.6428 124.666 24.4706C124.546 25.2993 124.345 25.8878 124.061 26.2361C123.778 26.5849 123.396 26.7591 122.917 26.7591C122.546 26.7591 122.203 26.672 121.887 26.4976C121.571 26.3232 121.315 26.0617 121.119 25.7128V14.3362C121.271 13.7917 121.533 13.3446 121.904 12.9957C122.274 12.6477 122.677 12.4727 123.113 12.4727C123.571 12.4727 123.925 12.6528 124.175 13.012C124.426 13.3717 124.6 13.9764 124.699 14.827C124.797 15.6767 124.846 16.8861 124.846 18.4553V21.038ZM128.981 12.8639C128.708 11.6001 128.267 10.6846 127.657 10.1174C127.047 9.55103 126.207 9.2677 125.14 9.2677C124.312 9.2677 123.538 9.50231 122.819 9.97069C122.1 10.4394 121.544 11.055 121.152 11.8176H121.119L121.119 1.25818H116.771V29.6998H120.498L120.956 27.8039H121.053C121.402 28.4795 121.925 29.0134 122.622 29.4059C123.32 29.7981 124.094 29.9944 124.944 29.9944C126.469 29.9944 127.592 29.2911 128.311 27.8857C129.03 26.48 129.39 24.2845 129.39 21.2981V18.1275C129.39 15.8827 129.253 14.1282 128.981 12.8639Z"
          fill="white"
    />
  </svg>
</template>